import React from 'react';
import { Handle } from 'react-flow-renderer';

const SuggestedQuestionsNode = React.memo(({ data, addUserMessage }) => {
  const { questions = [] } = data;

  return (
    <div
      className="suggested-questions-node"
      style={{
        border: '2px solid #444',
        padding: 10,
        backgroundColor: '#f0f0f0',
      }}
    >
      <h4>Suggested Questions</h4>
      {questions.map((q, i) => (
        <button
          key={i}
          onClick={() => addUserMessage(q, true)}
          style={{ margin: '5px', cursor: 'pointer' }}
        >
          {q}
        </button>
      ))}
      <Handle type="target" position="top" />
    </div>
  );
});

export default SuggestedQuestionsNode;
