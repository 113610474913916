// src/chatbot_components/utils/matching.js

/**
 * normalizeFilename(str):
 *  - lowercases everything
 *  - removes common file extensions (.pdf, .doc, .docx, .ppt, .pptx)
 *  - replaces underscores with spaces
 *  - removes "am104" prefix (optional, adjust as needed)
 *  - trims leftover spaces
 */
export function normalizeFilename(str) {
    if (!str) return '';
  
    let out = str.toLowerCase();
  
    // Remove common file extensions
    out = out.replace(/\.(pdf|docx?|pptx?)$/, '');
  
    // Convert underscores -> spaces
    out = out.replace(/_/g, ' ');
  
    // Optionally remove "am104" or other known prefixes if desired
    out = out.replace(/\bam104\s*/g, '');
  
    // Trim spaces
    out = out.trim();
  
    return out;
  }
  
  /**
   * partialMatch(a, b):
   *  - uses normalizeFilename on both a and b
   *  - then strips out spaces
   *  - returns true if the resulting strings contain one another
   *    e.g. "section1" includes "section1"
   */
  export function partialMatch(a, b) {
    const normA = normalizeFilename(a).replace(/\s+/g, '');
    const normB = normalizeFilename(b).replace(/\s+/g, '');
  
    if (!normA || !normB) return false;
  
    // Return true if one contains the other
    return normA.includes(normB) || normB.includes(normA);
  }
  