// src/chatbot_components/MainInterface/Nodes/UserNode.js

import React from 'react';
import { Handle } from 'react-flow-renderer';

const UserNode = React.memo(({ data }) => {
  return (
    <div
      className="user-node"
      style={{
        minWidth: 200,
        minHeight: 40,
        border: '2px solid #007bff',
        backgroundColor: '#fff',
        padding: 10,
      }}
    >
      {/* The TOP handle so edges can connect from initial-options -> user */}
      <Handle type="target" position="top" id="top" />

      <p>{data.label}</p>

      {/* The BOTTOM handle so the user node can connect to the chatbot node */}
      <Handle type="source" position="bottom" />
    </div>
  );
});

export default UserNode;
