// NyquistNode.jsx
import React from 'react';
import Plot from 'react-plotly.js';
import { Handle } from 'react-flow-renderer';

/**
 * Renders the Nyquist sampling data in two plots: time-domain & freq-domain.
 */
export default function NyquistNode({ data }) {
  const {
    plotData = {},
  } = data;

  if (!plotData || !plotData.originalTime) {
    return (
      <div style={{ padding: '10px', color: '#999' }}>
        <strong>Nyquist Node:</strong> No data yet.
      </div>
    );
  }

  const {
    originalTime,
    originalSignal,
    sampledTime,
    sampledSignal,
    reconstructedTime,
    reconstructedSignal,
    freqAxis_orig,
    fft_orig,
    freqAxis_samp,
    fft_samp,
    nyquistFreq,
    doesAlias
  } = plotData;

  const timeDomain = [
    {
      x: originalTime,
      y: originalSignal,
      mode: 'lines',
      name: 'Original',
      line: { color: 'blue' }
    },
    {
      x: sampledTime,
      y: sampledSignal,
      mode: 'markers',
      name: 'Sampled',
      marker: { color: 'red', size: 6 }
    },
    {
      x: reconstructedTime,
      y: reconstructedSignal,
      mode: 'lines',
      name: 'Reconstructed',
      line: { color: 'green', dash: 'dot' }
    }
  ];

  const freqDomain = [
    {
      x: freqAxis_orig,
      y: fft_orig,
      mode: 'lines',
      name: 'Original FFT',
      line: { color: 'blue' }
    },
    {
      x: freqAxis_samp,
      y: fft_samp,
      mode: 'lines',
      name: 'Sampled FFT',
      line: { color: 'red' }
    }
  ];

  return (
    <div
      className="nyquist-node"
      style={{
        minWidth: 600,
        background: '#fafafa',
        border: '1px solid #ccc',
        borderRadius: 8,
        padding: 10
      }}
    >
      <Handle type="target" position="top" />
      <div style={{ fontWeight: 'bold', marginBottom: 6 }}>Nyquist Sampling Visualization</div>
      {doesAlias && (
        <div style={{ color: 'red', marginBottom: 6 }}>
          <strong>Warning:</strong> Frequency above {nyquistFreq.toFixed(2)} Hz = Aliasing!
        </div>
      )}

      {/* Time-Domain Plot */}
      <Plot
        data={timeDomain}
        layout={{
          title: 'Time Domain',
          xaxis: { title: 'Time (s)' },
          yaxis: { title: 'Amplitude' },
          margin: { l: 40, r: 10, b: 40, t: 40 },
          showlegend: true,
          legend: {
            // Move legend to the right, centered vertically, inside the plot
            x: 1,
            y: 0.5,
            xanchor: 'left',
            yanchor: 'middle',
            // Make legend background opaque
            bgcolor: 'rgba(255,255,255,1)',
            // Add a solid border
            bordercolor: '#000',
            borderwidth: 1
            // Rounded corners for the legend are not supported by Plotly directly
          }
        }}
        style={{ width: '90%', height: '250px', margin: '0 auto' }}
      />

      {/* Frequency-Domain Plot */}
      <Plot
        data={freqDomain}
        layout={{
          title: 'Frequency Domain',
          xaxis: {
            type: 'log', // Log scale
            title: 'Frequency (Hz)'
          },
          yaxis: { title: 'Magnitude' },
          margin: { l: 40, r: 10, b: 40, t: 40 },
          showlegend: true,
          legend: {
            x: 1,
            y: 0.5,
            xanchor: 'left',
            yanchor: 'middle',
            bgcolor: 'rgba(255,255,255,1)',
            bordercolor: '#000',
            borderwidth: 1
            // Rounded corners for the legend are not supported by Plotly directly
          }
        }}
        style={{ width: '90%', height: '250px', margin: '0 auto' }}
      />

      <Handle type="source" position="bottom" />
    </div>
  );
}
