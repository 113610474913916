// DomainColorPlotterNode.js
import React, { useState, useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import Plot from 'react-plotly.js';
import axios from 'axios';

// Replace with your actual endpoint or config
import { SERVER_ENDPOINT } from '../../config';

import './DomainColorPlotterNode.css';

const DomainColorPlotterNode = React.memo(({ data }) => {
  // Data from parent:
  // data.funcExpression, data.plotData, data.isCmdKeyHeld
  const {
    funcExpression = 'z**2',
    plotData: initPlotData = null,
    isCmdKeyHeld = false,
  } = data;

  const [functionStr, setFunctionStr] = useState(funcExpression);
  const [resolution, setResolution] = useState(1000);
  const [plotData, setPlotData] = useState(initPlotData);
  const [error, setError] = useState(null);
  const [xRange, setXRange] = useState([-5, 5]);
  const [yRange, setYRange] = useState([-5, 5]);
  const [isLoading, setIsLoading] = useState(false);

  // Track whether the user is hovering over this node
  const [isHovered, setIsHovered] = useState(false);

  const plotRef = useRef(null);

  // Fetch domain-coloring data
  const fetchData = async (x_range = xRange, y_range = yRange) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${SERVER_ENDPOINT}/compute-transformation`,
        {
          function: functionStr,
          resolution,
          x_range,
          y_range,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setPlotData(response.data);
      setError(null);
    } catch (err) {
      console.error('Fetch error:', err);
      setError('Server error');
      setPlotData(null);
    }
    setIsLoading(false);
  };

  // Refetch on function/resolution changes
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionStr, resolution]);

  // Plotly relayout handler
  const handleRelayout = (event) => {
    const newXRange = [
      event['xaxis.range[0]'] ?? xRange[0],
      event['xaxis.range[1]'] ?? xRange[1],
    ];
    const newYRange = [
      event['yaxis.range[0]'] ?? yRange[0],
      event['yaxis.range[1]'] ?? yRange[1],
    ];
    setXRange(newXRange);
    setYRange(newYRange);
    fetchData(newXRange, newYRange);
  };

  return (
    <div
      className="complex-function-plotter-node"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Handle type="target" position="top" />

      <div className="node-label">Domain Color Plotter Node</div>

      {/* 
        Full overlay if user is NOT pressing Cmd (or Ctrl) AND the node is hovered.
        This prevents interaction with the plot unless Command is held.
      */}
      {!isCmdKeyHeld && isHovered && (
        <div className="command-overlay">
          <div className="overlay-text">
            Press Control <span className="command-key-symbol">^</span> to edit
          </div>
        </div>
      )}

      <div className="node-content">
        <div className="left-section">
          <div className="section-label">Settings</div>
          <label>
            Function:
            <input
              type="text"
              value={functionStr}
              onChange={(e) => setFunctionStr(e.target.value)}
            />
          </label>
          <label>
            Resolution:
            <input
              type="number"
              value={resolution}
              onChange={(e) =>
                setResolution(parseInt(e.target.value) || 1000)
              }
              min="100"
              max="2000"
              step="100"
            />
          </label>
        </div>

        <div className="center-section">
          {error && <div className="error">{error}</div>}
          {isLoading && <div className="loading">Loading...</div>}
          {plotData ? (
            <Plot
              ref={plotRef}
              data={[]}
              layout={{
                autosize: true,
                title: `f(z) = ${functionStr}`,
                xaxis: { range: xRange, title: 'Re(z)' },
                yaxis: {
                  range: yRange,
                  title: 'Im(z)',
                  scaleanchor: 'x',
                  scaleratio: 1,
                },
                images: plotData.image_base64
                  ? [
                      {
                        source: plotData.image_base64,
                        xref: 'x',
                        yref: 'y',
                        x: plotData.x_range[0],
                        y: plotData.y_range[1],
                        sizex: plotData.x_range[1] - plotData.x_range[0],
                        sizey: plotData.y_range[1] - plotData.y_range[0],
                        sizing: 'stretch',
                        layer: 'below',
                      },
                    ]
                  : [],
                margin: { t: 50, b: 50, l: 60, r: 20 },
              }}
              onRelayout={handleRelayout}
              useResizeHandler
              style={{
                width: '100%',
                height: '400px',
                // If Command is held, pointer events are allowed
                pointerEvents: isCmdKeyHeld ? 'auto' : 'none',
              }}
              config={{ scrollZoom: true }}
            />
          ) : (
            <div className="no-data">No Data Available</div>
          )}
        </div>

        {/* (Optional) Remove or keep a right-section if needed */}
        {/* <div className="right-section"> ... </div> */}
      </div>
    </div>
  );
});

export default DomainColorPlotterNode;
