// src/chatbot_components/MainInterface/Nodes/BranchCutVisualizationNode.js

import React, { useState, useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import Plot from 'react-plotly.js';
import axios from 'axios';

// If you have a config:
import { SERVER_ENDPOINT } from '../../config';
// else fallback: const SERVER_ENDPOINT = 'http://localhost:8080';

const BranchCutVisualizationNode = React.memo(({ data }) => {
  // data might have: { equation: "log(z)", plotData: { x:..., y:..., z:...}, plotType: "2D" or "3D" }
  const [equation, setEquation] = useState(data.equation || 'log(z)');
  const [plotType, setPlotType] = useState(data.plotType || '2D');
  const [plotData, setPlotData] = useState(data.plotData || null);
  const [error, setError] = useState(null);
  const [isInteractive, setIsInteractive] = useState(false);

  const nodeRef = useRef(null);

  // Optional: If you want to refetch from the server whenever user changes input
  const fetchData = async () => {
    try {
      const resp = await axios.post(`${SERVER_ENDPOINT}/compute-branchcut`, {
        equation,
        plot_type: plotType === '2D' ? '2D' : '3D',
      });
      setPlotData(resp.data.result);
      setError(null);
    } catch (err) {
      console.error('Error fetching branchcut data', err);
      setError('Server error');
    }
  };

  useEffect(() => {
    // If you want to automatically fetch from server each time we mount or eqn changes
    if (!data.plotData) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleInteractivity = () => {
    setIsInteractive((prev) => !prev);
  };

  // Handler for user changing eqn or plotType
  const handleSubmit = async (e) => {
    e.preventDefault();
    fetchData();
  };

  // Stop React Flow panning if isInteractive
  const stopFlowEvents = (e) => {
    if (isInteractive) e.stopPropagation();
  };

  let plotlyProps = {};
  if (plotData) {
    if (plotType === '2D') {
      plotlyProps = {
        data: [
          {
            x: plotData.x,
            y: plotData.y,
            z: plotData.z,
            type: 'contour',
            colorscale: 'HSV',
          },
        ],
        layout: {
          title: `Branch Cut 2D of ${equation}`,
          xaxis: { title: 'Re(z)' },
          yaxis: { title: 'Im(z)' },
        },
      };
    } else {
      // 3D
      plotlyProps = {
        data: [
          {
            x: plotData.x,
            y: plotData.y,
            z: plotData.z,
            type: 'surface',
            colorscale: 'HSV',
          },
        ],
        layout: {
          title: `Branch Cut 3D of ${equation}`,
          scene: {
            xaxis: { title: 'Re(z)' },
            yaxis: { title: 'Im(z)' },
            zaxis: { title: 'Angle or Real( )' },
          },
        },
      };
    }
  }

  return (
    <div
      className={`branchcut-visualization-node ${isInteractive ? 'interactive' : ''}`}
      ref={nodeRef}
      style={{ border: '2px solid #444', padding: 10, position: 'relative', width: 600 }}
      onWheel={stopFlowEvents}
      onMouseDown={stopFlowEvents}
    >
      <Handle type="target" position="top" />
      <div style={{ fontWeight: 'bold' }}>Branch Cut Visualization</div>

      <button
        onClick={toggleInteractivity}
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 2,
        }}
      >
        {isInteractive ? '🔓' : '🔒'}
      </button>

      <form onSubmit={handleSubmit} style={{ margin: '10px 0' }}>
        <label>
          Equation:
          <input
            type="text"
            value={equation}
            onChange={(e) => setEquation(e.target.value)}
            style={{ marginLeft: 4 }}
          />
        </label>
        <br />
        <label>
          Plot Type:
          <select
            value={plotType}
            onChange={(e) => setPlotType(e.target.value)}
            style={{ marginLeft: 4 }}
          >
            {/* <option value="2D">2D</option> */}
            <option value="3D">3D</option>
          </select>
        </label>
        <br />
        <button type="submit" style={{ marginTop: 5 }}>Fetch</button>
      </form>

      {error && <div style={{ color: 'red' }}>{error}</div>}

      {plotData ? (
        <Plot
          {...plotlyProps}
          style={{ width: '100%', height: '400px', pointerEvents: isInteractive ? 'auto' : 'none' }}
          useResizeHandler
        />
      ) : (
        <div>No Plot Data</div>
      )}
    </div>
  );
});

export default BranchCutVisualizationNode;
