// RiemannSurfaceNode.jsx
import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { SERVER_ENDPOINT } from '../../config';

export default function RiemannSurfaceNode({ data }) {
  // If the AI/server gave us initial data, store it:
  const initPlotData = data.plotData || null;

  // User inputs
  const [func, setFunc] = useState('sqrt(z)');
  const [mode, setMode] = useState('2D');
  const [branchAngle, setBranchAngle] = useState(Math.PI);
  const [xRange, setXRange] = useState([-2, 2]);
  const [yRange, setYRange] = useState([-2, 2]);
  const [resolution, setResolution] = useState(200);

  const [plotData, setPlotData] = useState(initPlotData);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const RIEMANN_ENDPOINT = `${SERVER_ENDPOINT}/riemann-surface`;

  // Manual fetch on button click
  const handleFetch = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
      const resp = await axios.post(
        RIEMANN_ENDPOINT,
        {
          function: func,
          mode,
          branchCutAngle: branchAngle,
          xRange,
          yRange,
          resolution,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setPlotData(resp.data);
    } catch (err) {
      console.error('Riemann fetch error:', err);
      setErrorMsg('Server error or CORS issue. Check console/logs.');
      setPlotData(null);
    }
    setIsLoading(false);
  };

  // If we have no data yet, show placeholders
  if (!plotData) {
    return (
      <div style={{ width: 600, background: '#eee', padding: 10 }}>
        <Handle type="target" position="top" />
        <h3>Riemann Surface Node (Manual Fetch)</h3>

        <div style={{ marginBottom: 10 }}>
          <label style={{ display: 'block' }}>
            Function:
            <input
              type="text"
              value={func}
              onChange={(e)=> setFunc(e.target.value)}
            />
          </label>

          <label style={{ display: 'block' }}>
            Mode:
            <select value={mode} onChange={(e)=> setMode(e.target.value)}>
              <option value="2D">2D</option>
              <option value="3D">3D</option>
            </select>
          </label>

          <label style={{ display: 'block' }}>
            Branch Angle:
            <input
              type="number"
              step="0.1"
              value={branchAngle}
              onChange={(e)=> setBranchAngle(parseFloat(e.target.value))}
            />
          </label>

          <label style={{ display: 'block' }}>
            X Range (e.g. -2,2):
            <input
              type="text"
              value={xRange.join(',')}
              onChange={(e) => {
                const parts = e.target.value.split(',').map(Number);
                setXRange(parts);
              }}
            />
          </label>

          <label style={{ display: 'block' }}>
            Y Range (e.g. -2,2):
            <input
              type="text"
              value={yRange.join(',')}
              onChange={(e) => {
                const parts = e.target.value.split(',').map(Number);
                setYRange(parts);
              }}
            />
          </label>

          <label style={{ display: 'block' }}>
            Resolution:
            <input
              type="number"
              value={resolution}
              onChange={(e)=> setResolution(parseInt(e.target.value) || 200)}
            />
          </label>
        </div>

        {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}

        <button onClick={handleFetch} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Fetch Riemann Surface'}
        </button>

        <Handle type="source" position="bottom" />
      </div>
    );
  }

  // We have data from the server
  const { mode: returnedMode } = plotData;

  // =========================
  // ======== 2D MODE ========
  // =========================
  if (returnedMode === '2D') {
    const X = plotData.xDomain;
    const Y = plotData.yDomain;
    const mag = plotData.magnitude;
    const phase = plotData.phase;

    const xFlat = X.flat();
    const yFlat = Y.flat();
    const phaseFlat = phase.flat();

    const trace2d = {
      x: xFlat,
      y: yFlat,
      z: phaseFlat,
      type: 'heatmap',
      colorscale: 'HSV',
    };

    return (
      <div style={{ width: 600, background: '#fafafa', padding: 10 }}>
        <Handle type="target" position="top" />
        <h3>Riemann Surface (2D) [Manual Fetch]</h3>

        <div style={{ marginBottom: 10 }}>
          <label style={{ display: 'block' }}>
            Function:
            <input
              type="text"
              value={func}
              onChange={(e)=> setFunc(e.target.value)}
            />
          </label>

          <label style={{ display: 'block' }}>
            Mode:
            <select value={mode} onChange={(e)=> setMode(e.target.value)}>
              <option value="2D">2D</option>
              <option value="3D">3D</option>
            </select>
          </label>

          <label style={{ display: 'block' }}>
            Branch Angle:
            <input
              type="number"
              step="0.1"
              value={branchAngle}
              onChange={(e)=> setBranchAngle(parseFloat(e.target.value))}
            />
          </label>

          <label style={{ display: 'block' }}>
            X Range (comma separated):
            <input
              type="text"
              value={xRange.join(',')}
              onChange={(e) => {
                const parts = e.target.value.split(',').map(Number);
                setXRange(parts);
              }}
            />
          </label>

          <label style={{ display: 'block' }}>
            Y Range:
            <input
              type="text"
              value={yRange.join(',')}
              onChange={(e) => {
                const parts = e.target.value.split(',').map(Number);
                setYRange(parts);
              }}
            />
          </label>

          <label style={{ display: 'block' }}>
            Resolution:
            <input
              type="number"
              value={resolution}
              onChange={(e)=> setResolution(parseInt(e.target.value) || 200)}
            />
          </label>
        </div>

        {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}

        <button onClick={handleFetch} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Fetch Riemann Surface'}
        </button>

        <Plot
          data={[trace2d]}
          layout={{
            width: 500,
            height: 400,
            title: `2D Mode: Arg(f(z)), BranchCutAngle=${plotData.branchCutAngle}`,
            xaxis: { title: 'Re(z)' },
            yaxis: { title: 'Im(z)' },
          }}
        />

        <Handle type="source" position="bottom" />
      </div>
    );
  }

  // =========================
  // ======== 3D MODE ========
  // =========================
  const X = plotData.xDomain;
  const Y = plotData.yDomain;
  const realVals = plotData.realPart; 
  const imagVals = plotData.imagPart;

  // Flatten
  const xFlat = X.flat();
  const yFlat = Y.flat();
  const reFlat = realVals.flat();
  const imFlat = imagVals.flat();

  // We'll do two side-by-side 3D plots:
  //   1) Real(f(z)) as z coordinate
  //   2) Imag(f(z)) as z coordinate

  const traceReal = {
    x: xFlat,
    y: yFlat,
    z: reFlat,
    mode: 'markers',
    marker: { color: reFlat, colorscale: 'Portland', size: 2 },
    type: 'scatter3d',
  };

  const traceImag = {
    x: xFlat,
    y: yFlat,
    z: imFlat,
    mode: 'markers',
    marker: { color: imFlat, colorscale: 'Picnic', size: 2 },
    type: 'scatter3d',
  };

  return (
    <div style={{ width: 900, background: '#fafafa', padding: 10 }}>
      <Handle type="target" position="top" />
      <h3>Riemann Surface (3D) [Manual Fetch]</h3>

      <div style={{ marginBottom: 10 }}>
        <label style={{ display: 'block' }}>
          Function:
          <input
            type="text"
            value={func}
            onChange={(e)=> setFunc(e.target.value)}
          />
        </label>

        <label style={{ display: 'block' }}>
          Mode:
          <select value={mode} onChange={(e)=> setMode(e.target.value)}>
            <option value="2D">2D</option>
            <option value="3D">3D</option>
          </select>
        </label>

        <label style={{ display: 'block' }}>
          Branch Angle:
          <input
            type="number"
            step="0.1"
            value={branchAngle}
            onChange={(e)=> setBranchAngle(parseFloat(e.target.value))}
          />
        </label>

        <label style={{ display: 'block' }}>
          X Range:
          <input
            type="text"
            value={xRange.join(',')}
            onChange={(e) => {
              const parts = e.target.value.split(',').map(Number);
              setXRange(parts);
            }}
          />
        </label>

        <label style={{ display: 'block' }}>
          Y Range:
          <input
            type="text"
            value={yRange.join(',')}
            onChange={(e) => {
              const parts = e.target.value.split(',').map(Number);
              setYRange(parts);
            }}
          />
        </label>

        <label style={{ display: 'block' }}>
          Resolution:
          <input
            type="number"
            value={resolution}
            onChange={(e)=> setResolution(parseInt(e.target.value) || 200)}
          />
        </label>
      </div>

      {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}

      <button onClick={handleFetch} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Fetch Riemann Surface'}
      </button>

      {/* Side-by-side container */}
      <div style={{ display: 'flex', gap: '1rem', marginTop: 10 }}>
        {/* Left: Real part */}
        <Plot
          data={[traceReal]}
          layout={{
            width: 400,
            height: 400,
            title: `Real(f(z)), branchCut=${plotData.branchCutAngle}`,
            scene: {
              xaxis: { title: 'Re(z)' },
              yaxis: { title: 'Im(z)' },
              zaxis: { title: 'Re(f(z))' },
            },
          }}
        />

        {/* Right: Imag part */}
        <Plot
          data={[traceImag]}
          layout={{
            width: 400,
            height: 400,
            title: `Imag(f(z)), branchCut=${plotData.branchCutAngle}`,
            scene: {
              xaxis: { title: 'Re(z)' },
              yaxis: { title: 'Im(z)' },
              zaxis: { title: 'Im(f(z))' },
            },
          }}
        />
      </div>

      <Handle type="source" position="bottom" />
    </div>
  );
}
