// src/chatbot_components/MainInterface/Menu/WhatsNewOverlay.js
import React, { useState } from 'react';
import './WhatsNewOverlay.css';

function WhatsNewOverlay({ slides, onClose }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  // In WhatsNewOverlay.js (or wherever you render the image)
const imagePath = slides[currentIndex].image;
let resolvedSrc;
try {
  resolvedSrc = require(`./${imagePath}`);
} catch(e) {
  resolvedSrc = null;
}


  if (!slides || !slides.length) {
    return (
      <div className="whatsnew-overlay">
        <div className="overlay-inner">
          <button className="close-button" onClick={onClose}>
            X
          </button>
          <h2>No "What's New" slides available</h2>
        </div>
      </div>
    );
  }

  // Move to previous slide or wrap
  const goPrev = () => {
    setCurrentIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  // Move to next slide or wrap
  const goNext = () => {
    setCurrentIndex((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const { image, title, description } = slides[currentIndex];

  return (
    <div className="whatsnew-overlay">
      <div className="overlay-inner">
        <button className="close-button" onClick={onClose}>X</button>

        <div className="slide-content">
          <h2 className="slide-title">{title}</h2>
          {image && (
            <img
              src={image}
              alt={title}
              className="slide-image"
            />
          )}
          <p className="slide-description">{description}</p>

          {/* Arrows */}
          <button className="nav-arrow left-arrow" onClick={goPrev}>
            ←
          </button>
          <button className="nav-arrow right-arrow" onClick={goNext}>
            →
          </button>

          {/* Dots */}
          <div className="dots-container">
            {slides.map((_, idx) => (
              <span
                key={idx}
                className={`dot ${idx === currentIndex ? 'active' : ''}`}
                onClick={() => setCurrentIndex(idx)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhatsNewOverlay;
