// src/chatbot_components/MainInterface/Menu/Menu.js
import React, { useState } from 'react';
import './Menu.css';
import whatsNewSlides from './whatsNewItems.json';
import WhatsNewOverlay from './WhatsNewOverlay';

function Menu({
  userInfo,
  selectedClass,
  showSettingsModal,
  setShowSettingsModal,
  openPrivacyOverlay,
  logout,
  isNightMode,
  menuImage,
  switchCourse,
}) {
  // Local state to show/hide the "What's New?" overlay
  const [showWhatsNew, setShowWhatsNew] = useState(false);

  return (
    <div className="sidebar-menu-left">
      {menuImage && (
        <img
          src={menuImage}
          alt="Menu Logo"
          style={{
            display: 'block',
            margin: '0 auto 10px auto',
            maxWidth: '200px',
          }}
        />
      )}

      <h2 className="menu-title">
        Hello, {userInfo.firstName} {userInfo.lastName}
      </h2>

      <br />
      <div className="course-info">
        <div><b>Course:</b></div>
        <span className="course-badge">{selectedClass}</span>
      </div>
      <div className="course-info">
        <div><b>Role:</b></div>
        <span className="role-badge">{userInfo.roles.join(', ')}</span>
      </div>
      <hr className="menu-divider" />

      {/* Settings */}
      <button
        className="menu-button tutorial-button"
        disabled
      > Settings (Upcoming)</button>
    
      {/* Privacy */}
      <button
        className="menu-button"
        onClick={openPrivacyOverlay}
      >
        Privacy Policy
      </button>

      {/* Change Course */}
      <button
        className="menu-button switch-button"
        onClick={switchCourse}
      >
        Change Course
      </button>

      {/* NEW: “What’s New?” button, active */}
      <button
        className="menu-button whatsnew-button"
        onClick={() => setShowWhatsNew(true)}
      >
        What’s New?
      </button>

      {/* NEW: “Tutorial” button, disabled */}
      <button
        className="menu-button tutorial-button"
        disabled
      >
        Tutorial (Coming Soon)
      </button>

      {/* Logout */}
      <button className="menu-button logout-button" onClick={logout}>
        Logout
      </button>

      {/* If showWhatsNew is true, show the slideshow overlay */}
      {showWhatsNew && (
        <WhatsNewOverlay
          slides={whatsNewSlides}
          onClose={() => setShowWhatsNew(false)}
        />
      )}
    </div>
  );
}

export default Menu;
