import UserNode from './UserNode';
import ChatbotNode from './ChatbotNode';
import SuggestedQuestionsNode from './SuggestedQuestionsNode';
import InitialOptionsNode from './InitialOptionsNode';
import ReferenceNode from './ReferenceNode';
import VisualNode from './VisualNode';
import DomainColorPlotterNode from './DomainColorPlotterNode';
import BranchCutVisualizationNode from './BranchCutVisualizationNode';
import NyquistNode from './NyquistNode';
import ConformalNode from './ConformalNode';
import MobiusNode from './MobiusNode';
import RiemannSurfaceNode from './RiemannSurfaceNode';
// 1) Import your new VisualSelectionNode:
import VisualSelectionNode from './VisualSelectionNode';
import ContourAndPoleNode from './ContourAndPoleNode';
export default function getNodeTypes() {
  return {
    user: UserNode,
    chatbot: ChatbotNode,
    suggestedQuestions: SuggestedQuestionsNode,
    initialOptions: InitialOptionsNode,
    reference: ReferenceNode,
    visual: VisualNode,
    domainColorPlotter: DomainColorPlotterNode,
    branchCutVisualization: BranchCutVisualizationNode,
    nyquistNode: NyquistNode,  // <-- new
    conformalNode: ConformalNode,  // <-- new
    mobiusNode: MobiusNode,
    riemannSurfaceNode: RiemannSurfaceNode,
    contourAndPoleNode: ContourAndPoleNode, // <-- new


    // 2) Register the new node type
    visualSelection: VisualSelectionNode,
  };
}
