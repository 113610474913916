// src/chatbot_components/MainInterface/Nodes/ChatbotNode.js
import React, { useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import { FaCopy, FaFlag } from 'react-icons/fa';
// Import the function
import renderTextWithFormatting from '../Rendering/renderTextWithFormatting';

const ChatbotNode = React.memo(({ data }) => {
  const contentRef = useRef();

  const copyToClipboard = () => {
    if (!contentRef.current) return;
    const text = contentRef.current.innerText;
    navigator.clipboard.writeText(text).catch((err) => console.error(err));
  };

  const flagContent = () => {
    console.log('Flagging content:', data.label);
  };

  // Pass data.label into our rendering function
  const renderedContent = renderTextWithFormatting(data.label || '');

  return (
    <div
      className="chatbot-node"
      style={{
        minWidth: 200,
        minHeight: 40,
        border: '2px solid #444',
        backgroundColor: '#f9f9f9',
        padding: 10,
        position: 'relative',
      }}
    >
      <div ref={contentRef}>{renderedContent}</div>
{/* 
      <div style={{ position: 'absolute', bottom: 5, right: 5, display: 'flex', gap: 5 }}>
        <button onClick={copyToClipboard} title="Copy"><FaCopy /></button>
        <button onClick={flagContent} title="Flag"><FaFlag /></button>
      </div> */}

      <Handle type="target" position="top" />
      <Handle type="source" position="right" />
      <Handle type="source" position="bottom" />
    </div>
  );
});

export default ChatbotNode;
