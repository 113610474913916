import React, { useState, useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { SERVER_ENDPOINT } from '../../config';

export default function ContourAndPoleNode({ data }) {
  // The function from the AI, or fallback
  const functionFromAI = data.function || '(z+1)/(z-1)';

  // The server data (xGrid, yGrid, reValues, etc.)
  const initPlotData = data.plotData || null;

  // For demonstration, let's store the plotData in state so we can refetch if needed
  const [plotData, setPlotData] = useState(initPlotData);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  // In a simpler pattern, you might always rely on `data.plotData` alone
  // But let's say we want a button "Refetch" or something.

  // We won't store xRange, yRange, resolution in state if we're using the data from AI or from the server directly.
  // But if you do want them, you can store them. For now, let's keep it minimal.

  const handleRefetch = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
      // Just example if you want to do a local fetch again:
      const resp = await axios.post(`${SERVER_ENDPOINT}/complex-contours`, {
        function: functionFromAI,
        xRange: [-2, 2],
        yRange: [-2, 2],
        resolution: 100,
      });
      setPlotData(resp.data);
    } catch (err) {
      console.error('Contour fetch error:', err);
      setErrorMsg('Server/CORS error. See console.');
      setPlotData(null);
    }
    setIsLoading(false);
  };

  return (
    <div style={{ width: 700, background: '#fafafa', padding: 10 }}>
      <Handle type="target" position="top" />
      <h3>Contour + Pole Visualization</h3>

      {/* Show the function that came from AI or fallback */}
      <p><strong>f(z) =</strong> {functionFromAI}</p>

      {/* Error or loading status */}
      {errorMsg && <p style={{ color: 'red' }}>{errorMsg}</p>}
      {isLoading && <p>Loading...</p>}

      {/* Optional button if you want to re-fetch */}
      <button onClick={handleRefetch}>
        Refetch Contours
      </button>

      {/* If we have data, show the Plot */}
      {plotData ? (
        <ContourPlot plotData={plotData} />
      ) : (
        <p>No data or server error.</p>
      )}
      
      <Handle type="source" position="bottom" />
    </div>
  );
}

// Sub-component for the actual Plot
function ContourPlot({ plotData }) {
  const { xGrid, yGrid, reValues, poleLocations } = plotData;

  // Flatten
  const xFlat = xGrid.flat();
  const yFlat = yGrid.flat();
  const reFlat = reValues.flat();

  // The contour trace
  const contourTrace = {
    x: xFlat,
    y: yFlat,
    z: reFlat,
    type: 'contour',
    colorscale: 'RdBu',
    ncontours: 20,
    line: { smoothing: 1 },
    name: 'Re(f(z))',
  };

  let poleMarkers = null;
  if (poleLocations && poleLocations.length > 0) {
    const px = [];
    const py = [];
    for (const [pxVal, pyVal] of poleLocations) {
      px.push(pxVal);
      py.push(pyVal);
    }
    poleMarkers = {
      x: px,
      y: py,
      mode: 'markers',
      name: 'Poles',
      marker: { color: 'black', symbol: 'x', size: 10 },
    };
  }

  const dataTraces = poleMarkers ? [contourTrace, poleMarkers] : [contourTrace];

  return (
    <Plot
      data={dataTraces}
      layout={{
        width: 600,
        height: 500,
        title: 'Re(f(z)) Contours & Poles',
        xaxis: { title: 'Re(z)' },
        yaxis: { title: 'Im(z)' },
      }}
    />
  );
}
