// src/chatbot_components/MainInterface/Menu/FileMenu.js

import React, { useState, useEffect, useRef } from 'react';
import './FileMenu.css';
import fileData from './fileData.json';
import fileData2 from './fileData2.json';
import PedagogicalOutcomesOverlay from './PedagogicalOutcomesOverlay';
import { partialMatch } from '../../utils/matching';

/**
 * FileMenu
 *  - We highlight any file that matches "highlightedReferences"
 *  - If "scrollToFileRef" is provided, we open (uncollapse) the menu and scroll to the matching file
 *
 * Props:
 *   data                => the course's file data
 *   highlightedReferences => array of reference strings to highlight
 *   scrollToFileRef     => a specific reference we want to scroll to
 *   isCollapsed         => from the parent, telling us whether to show or hide
 *   setCollapsed        => from the parent, function to toggle or set collapsed state
 */
function FileMenu({
  data,
  highlightedReferences = [],
  scrollToFileRef,
  isCollapsed,
  selectedClass,
  setCollapsed,
}) {
  // If no data is provided, fallback to local fileData
  const fallbackData = {
    pinnedFiles: fileData2.pinnedFiles || [],
    categories: fileData2.categories || [],
  };
  if (selectedClass === "am104"){
    const fallbackData = {
      pinnedFiles: fileData.pinnedFiles || [],
      categories: fileData.categories || [],
    };
  } else if (selectedClass === "physics15a"){
    const fallbackData = {
      pinnedFiles: fileData2.pinnedFiles || [],
      categories: fileData2.categories || [],
    };
  }
  
  const finalData =
    !data || !data.pinnedFiles || !data.categories
      ? fallbackData
      : data;

  // We no longer manage `collapsed` locally. Use isCollapsed from props.
  // For searching file names
  const [searchTerm, setSearchTerm] = useState('');

  // For expanding/collapsing each "Week"
  const [expandedWeeks, setExpandedWeeks] = useState({});

  // Overlays
  const [overlayFile, setOverlayFile] = useState(null);
  const [showPedagogyOverlay, setShowPedagogyOverlay] = useState(false);

  // We'll store Refs for each file item so we can scroll into view
  const fileRefs = useRef({});

  // A function to see if a file should be highlighted
  const shouldHighlight = (fileName, fileLink) => {
    return highlightedReferences.some((ref) => {
      return partialMatch(ref, fileName) || partialMatch(ref, fileLink);
    });
  };

  // A function to see if a file matches "scrollToFileRef"
  const doesMatchScrollRef = (fileName, fileLink) => {
    if (!scrollToFileRef) return false;
    return partialMatch(scrollToFileRef, fileName) || partialMatch(scrollToFileRef, fileLink);
  };

  // Expand/collapse a particular "Week"
  const toggleWeek = (catIdx, weekIdx) => {
    const key = `${catIdx}-${weekIdx}`;
    setExpandedWeeks((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // For searching file names
  const matchesSearch = (fileName) => {
    return fileName.toLowerCase().includes(searchTerm.toLowerCase());
  };

  // When the user clicks a file
  const handleFileClick = (file) => {
    if (file.id === 1) {
      setShowPedagogyOverlay(true);
    } else {
      setOverlayFile(file);
    }
  };
  const closeOverlay = () => setOverlayFile(null);
  const closePedagogyOverlay = () => setShowPedagogyOverlay(false);

  /**
   * useEffect: If "scrollToFileRef" changes, we:
   *  1) Force the menu open (setCollapsed(false))
   *  2) Search for a pinned/category file that partialMatches
   *  3) Expand the correct "Week"
   *  4) Scroll the matching file into view
   */
  useEffect(() => {
    if (!scrollToFileRef) return;

    // Force the menu open
    setCollapsed(false);

    let foundKey = null;

    // 1) Check pinned files
    for (const pinned of finalData.pinnedFiles) {
      if (doesMatchScrollRef(pinned.name, pinned.link)) {
        foundKey = `pinned-${pinned.id}`;
        break;
      }
    }

    // 2) If not found, check categories
    if (!foundKey) {
      categoryLoop: for (let cIdx = 0; cIdx < finalData.categories.length; cIdx++) {
        const cat = finalData.categories[cIdx];
        for (let wIdx = 0; wIdx < cat.weeks.length; wIdx++) {
          const wk = cat.weeks[wIdx];
          for (let fIdx = 0; fIdx < wk.files.length; fIdx++) {
            const fi = wk.files[fIdx];
            if (doesMatchScrollRef(fi.name, fi.link)) {
              // We found it => expand that week
              const key = `${cIdx}-${wIdx}`;
              setExpandedWeeks((prev) => ({ ...prev, [key]: true }));
              foundKey = `cat-${cIdx}-week-${wIdx}-file-${fIdx}`;
              break categoryLoop;
            }
          }
        }
      }
    }

    // 3) If foundKey is set, scroll into view
    if (foundKey && fileRefs.current[foundKey]) {
      setTimeout(() => {
        fileRefs.current[foundKey].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 200);
    }
  }, [scrollToFileRef, doesMatchScrollRef, finalData, setCollapsed]);

  return (
    <>
      {overlayFile && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={closeOverlay}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              maxWidth: '80%',
              cursor: 'auto',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{overlayFile.name}</h2>
            <p>Type: {overlayFile.type ? overlayFile.type.toUpperCase() : 'N/A'}</p>
            <p>
              {overlayFile.link ? (
                <a
                  href={overlayFile.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download or View File
                </a>
              ) : (
                <span>No link available.</span>
              )}
            </p>
            <button onClick={closeOverlay}>Close</button>
          </div>
        </div>
      )}

      {showPedagogyOverlay && (
        <PedagogicalOutcomesOverlay onClose={closePedagogyOverlay} />
      )}

      <div className={`file-menu-right ${isCollapsed ? 'collapsed' : ''}`}>
        {/* If you want a secondary button inside the FileMenu itself: */}
        <button
          className="collapse-button"
          onClick={() => setCollapsed((prev) => !prev)}
          title={isCollapsed ? 'Show Files' : 'Hide Files'}
        >
          {isCollapsed ? '>' : '<'}
        </button>

        {/* Pinned files */}
        <div className="pinned-file-wrapper">
          <div className="pinned-header">📌 Pinned Files</div>
          {finalData.pinnedFiles.length === 0 && (
            <div style={{ fontSize: '12px', color: '#999' }}>
              No pinned files found.
            </div>
          )}
          {finalData.pinnedFiles.map((fileItem) => {
            const iconClass =
              fileItem.type === 'pin' ? 'pinnedfile-icon' : fileItem.type + '-icon';
            const iconLabel =
              fileItem.type === 'pin'
                ? ''
                : fileItem.type?.toUpperCase() || 'FILE';

            const highlight = shouldHighlight(fileItem.name, fileItem.link);
            const itemKey = `pinned-${fileItem.id}`;

            return (
              <div
                className="file-item"
                key={fileItem.id}
                ref={(el) => (fileRefs.current[itemKey] = el)}
                onClick={() => handleFileClick(fileItem)}
                style={{
                  border: highlight ? '2px solid green' : 'none',
                  transition: 'border 0.3s',
                }}
              >
                <div className={`file-icon ${iconClass}`}>
                  {iconLabel}
                </div>
                <div className="file-name">{fileItem.name}</div>
              </div>
            );
          })}
        </div>

        <hr className="file-menu-divider" />

        {/* Category-based files */}
        {finalData.categories.length === 0 && (
          <div style={{ fontSize: '12px', color: '#999', padding: '0 15px' }}>
            No categories found.
          </div>
        )}
        {finalData.categories.map((category, catIdx) => (
          <div key={catIdx}>
            <h2 className="file-menu-title">{category.title}</h2>
            {category.weeks.map((week, weekIdx) => {
              // Filter by search
              const hasMatch = week.files.some((f) => matchesSearch(f.name));
              if (!hasMatch && searchTerm.trim() !== '') {
                return null;
              }

              // Expand/collapse this week
              const wkKey = `${catIdx}-${weekIdx}`;
              const isExpanded = expandedWeeks[wkKey] || false;

              return (
                <div key={weekIdx} className="week-wrapper">
                  <div
                    className="subheading"
                    onClick={() => toggleWeek(catIdx, weekIdx)}
                  >
                    <span>{week.weekTitle}</span>
                    <span>{isExpanded ? '[-]' : '[+]'} </span>
                  </div>

                  <div className={`week-files ${isExpanded ? 'expanded' : ''}`}>
                    {week.files
                      .filter((fi) => matchesSearch(fi.name))
                      .map((fi, fIdx) => {
                        const iconClass = fi.type + '-icon';
                        const iconLabel = fi.type?.toUpperCase() || 'FILE';

                        const highlight = shouldHighlight(fi.name, fi.link);
                        const itemKey = `cat-${catIdx}-week-${weekIdx}-file-${fIdx}`;

                        return (
                          <div
                            className="file-item"
                            key={fIdx}
                            ref={(el) => (fileRefs.current[itemKey] = el)}
                            onClick={() => handleFileClick(fi)}
                            style={{
                              border: highlight ? '2px solid green' : 'none',
                              transition: 'border 0.3s',
                            }}
                          >
                            <div className={`file-icon ${iconClass}`}>
                              {iconLabel}
                            </div>
                            <div className="file-name">{fi.name}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        ))}

        {/* Search bar */}
        <div className="search-container">
          <input
            type="text"
            className="search-bar"
            placeholder="Search files..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

export default FileMenu;