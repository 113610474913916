// src/chatbot_components/MainInterface/Rendering/renderTextWithFormatting.js
import React from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const renderTextWithFormatting = (text) => {
  if (typeof text !== 'string') {
    console.error('Invalid text input:', text);
    return 'Invalid text'; 
  }

  const renderLatex = (latexContent) => {
    try {
      // If the LaTeX content starts with "[" or "(" and ends with "\]" or "\)", strip them
      if (
        (latexContent.startsWith('[') || latexContent.startsWith('(')) &&
        (latexContent.endsWith('\\]') || latexContent.endsWith('\\)'))
      ) {
        latexContent = latexContent.slice(1, -2);
      }

      return katex.renderToString(latexContent, { throwOnError: false, displayMode: false });
    } catch (error) {
      console.error('Error rendering LaTeX:', latexContent, error);
      return latexContent;
    }
  };

  const processText = (rawText) => {
    // Remove text between 【 and 】
    rawText = rawText.replace(/【.*?】/g, '');

    const parts = [];
    let buffer = '';
    let inBold = false;
    let inLatex = false;
    let partType = 'normal';
    let latexBuffer = '';

    const latexStartRegex = /(\$\$|\\\[|\\\(|\$|\\begin\{matrix\}|\\begin\{pmatrix\})/;
    const latexEndRegex = /(\$\$|\\\]|\\\)|\$|\\end\{matrix\}|\\end\{pmatrix\})/;

    for (let i = 0; i < rawText.length; i++) {
      buffer += rawText[i];

      // LaTeX start
      if (!inLatex && latexStartRegex.test(buffer)) {
        if (buffer.length > 1) {
          parts.push({ type: partType, content: buffer.slice(0, -1) });
          buffer = buffer.slice(-1);
        }
        if (parts.length > 0 && parts[parts.length - 1].content.endsWith('\\')) {
          parts[parts.length - 1].content = parts[parts.length - 1].content.slice(0, -1);
        }
        inLatex = true;
        partType = 'latex';
        latexBuffer = buffer; 
        buffer = '';
        continue;
      }

      // Accumulate LaTeX
      if (inLatex) {
        latexBuffer += rawText[i];
        if (latexEndRegex.test(latexBuffer)) {
          inLatex = false;
          parts.push({ type: partType, content: latexBuffer });
          latexBuffer = '';
          partType = 'normal';
          buffer = ''; 
          continue;
        }
        continue;
      }

      // Bold check (**)
      if (!inBold && buffer.endsWith('**')) {
        inBold = true;
        parts.push({ type: partType, content: buffer.slice(0, -2) });
        buffer = '';
        partType = 'bold';
        continue;
      } else if (inBold && buffer.endsWith('**')) {
        inBold = false;
        parts.push({ type: partType, content: buffer.slice(0, -2) });
        buffer = '';
        partType = 'normal';
        continue;
      }

      // Possible new line
      if (buffer.endsWith('\n')) {
        parts.push({ type: partType, content: buffer.slice(0, -1) });
        parts.push({ type: 'newline' });
        buffer = '';
        continue;
      }
    }

    if (buffer) {
      parts.push({ type: partType, content: buffer });
    }

    return parts;
  };

  const renderPart = (part, idx) => {
    let content = part.content;
    if (part.type === 'latex') {
      content = renderLatex(content);
    }

    switch (part.type) {
      case 'normal':
        return (
          <span key={idx} style={{ lineHeight: '1.5' }}>
            {content}
          </span>
        );
      case 'bold':
        return (
          <b key={idx} style={{ lineHeight: '1.5' }}>
            {content}
          </b>
        );
      case 'latex':
        return (
          <span
            key={idx}
            style={{ lineHeight: '1.5' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        );
      case 'newline':
        return <br key={idx} />;
      default:
        return content;
    }
  };

  const processedParts = processText(text);

  return (
    <div style={{ display: 'inline' }}>
      {processedParts.map((part, i) => renderPart(part, i))}
    </div>
  );
};

export default renderTextWithFormatting;
