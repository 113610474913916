// src/chatbot_components/MainInterface/Nodes/ReferenceNode.js

import React from 'react';
import { Handle } from 'react-flow-renderer';
import fileData from '../Menu/fileData.json';
import { partialMatch } from '../../utils/matching';

// Flatten pinned + categories => array of { name, link }
function getAllFiles() {
  const pinned = fileData.pinnedFiles.map((f) => ({ name: f.name, link: f.link }));
  const catFiles = fileData.categories.flatMap((cat) =>
    cat.weeks.flatMap((wk) =>
      wk.files.map((f) => ({ name: f.name, link: f.link }))
    )
  );
  return [...pinned, ...catFiles];
}

const allFiles = getAllFiles();

/**
 * If data.references = [...], we highlight any references that match a file's name or link.
 * Also, if data.onReferenceClick is provided, we make the <li> clickable so the user can 
 * jump to that file in the FileMenu.
 */
const ReferenceNode = React.memo(({ data }) => {
  const { references = [], onReferenceClick } = data; 

  return (
    <div
      className="reference-node"
      style={{
        border: '2px solid #ffb703',
        backgroundColor: '#fff8db',
        padding: 10,
        borderRadius: 5,
        minWidth: 250,
      }}
    >
      <h4 style={{ marginTop: 0 }}>References</h4>
      {references.length === 0 ? (
        <p>No references found.</p>
      ) : (
        <ul style={{ margin: 0, paddingLeft: 20 }}>
          {references.map((ref, idx) => {
            const found = allFiles.some((fileObj) => {
              return partialMatch(ref, fileObj.name) || partialMatch(ref, fileObj.link);
            });

            // We'll style the li with a pointer if onReferenceClick is provided
            const cursorStyle = onReferenceClick ? 'pointer' : 'default';

            return (
              <li
                key={idx}
                style={{
                  border: found ? '2px solid green' : 'none',
                  marginBottom: '4px',
                  padding: '2px',
                  cursor: cursorStyle,
                }}
                // If we have onReferenceClick, attach an onClick
                onClick={() => {
                  if (onReferenceClick) {
                    onReferenceClick(ref);
                  }
                }}
              >
                {ref}
              </li>
            );
          })}
        </ul>
      )}

      <Handle type="target" position="left" style={{ background: '#555' }} />
    </div>
  );
});

export default ReferenceNode;
