// src/chatbot_components/MainInterface/Nodes/VisualSelectionNode.js

import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import './VisualSelectionNode.css';

const VisualSelectionNode = memo(({ data }) => {
  const { visuals = [], onVisualSelect, selectedPrompt } = data;
  const hasSelection = selectedPrompt !== null;

  return (
    <div className="visual-selection-node">
      <Handle type="target" position="top" />
      <div className="visual-selection-title">Available Visuals</div>

      <div className="visual-card-grid">
        {visuals.map((item, idx) => {
          const isSelected = selectedPrompt === item.prompt;
          return (
            <div
              key={idx}
              className={
                'visual-card ' +
                (isSelected ? 'used-card' : hasSelection ? 'greyed-out' : '')
              }
              onClick={() => {
                if (!hasSelection) {
                  onVisualSelect(item.prompt);
                }
              }}
            >
              <div className="visual-card-image-container">
                <img src={item.src} alt={item.title} />
                <div className="visual-card-hover">
                  <p>{item.description}</p>
                </div>
              </div>
              <div className="visual-card-label">{item.title}</div>
            </div>
          );
        })}
      </div>

      <Handle type="source" position="bottom" />
    </div>
  );
});

export default VisualSelectionNode;
