// src/Sockets/useChatbotSocket.js
import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { SERVER_ENDPOINT } from '../config';

/**
 * Custom Hook: useChatbotSocket
 *
 * Abstracts Socket.IO logic into a reusable hook.
 * - Accepts callbacks for handling incoming data.
 * - Returns a ref to the socket so you can emit messages easily.
 */
export default function useChatbotSocket({
  selectedClass,
  handleSocketResponse,
  handleSuggestedQuestions,
  handleVisualizationData,
}) {
  const socketRef = useRef(null);

  useEffect(() => {
    // Initialize / re-initialize the socket
    socketRef.current = io(SERVER_ENDPOINT, {
      transports: ['polling'],
      reconnectionAttempts: 5,
      timeout: 40000,
      path: '/socket.io',
      auth: {
        class_name: selectedClass,
      },
    });

    const socket = socketRef.current;

    // Basic event handlers
    socket.on('connect', () => {
      console.log('Connected to server:', SERVER_ENDPOINT);
    });

    socket.on('final_response', (data) => {
      console.log('Receiving Response:', data);
      handleSocketResponse(data);
    });

    socket.on('suggested_questions', (data) => {
      handleSuggestedQuestions(data);
    });

    socket.on('visualization_data', (data) => {
      console.log('Received Visualization Data:', data);
      handleVisualizationData(data);
    });

    // Error / reconnect logic
    socket.on('error', (error) => {
      console.error('Socket.IO Error:', error);
      socket.disconnect();
    });
    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
      socket.disconnect();
    });
    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      socket.disconnect();
    });
    socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected successfully on attempt ${attemptNumber}`);
    });

    // Cleanup on unmount or re-render
    return () => {
      socket.off('connect');
      socket.off('final_response');
      socket.off('suggested_questions');
      socket.off('visualization_data');
      socket.off('error');
      socket.off('reconnect_attempt');
      socket.off('connect_error');
      socket.off('reconnect');
      // Optionally: socket.disconnect();
    };
  }, [
    selectedClass,
    handleSocketResponse,
    handleSuggestedQuestions,
    handleVisualizationData,
  ]);

  // Return the ref so the parent can emit messages
  return socketRef;
}
