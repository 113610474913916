import React, { useState, useEffect } from "react";
import axios from "axios";
import ScrollToBottom from "react-scroll-to-bottom";
import "./GENED1091.css";

const GENED1091 = () => {
  const [threadId, setThreadId] = useState("");
  const [assistants, setAssistants] = useState([]);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // Start a conversation on component mount
  useEffect(() => {
    const startConversation = async () => {
      try {
        const response = await axios.post("http://127.0.0.1:5001/api/start_conversation");
        setThreadId(response.data.thread_id);
        setAssistants(response.data.assistants);
      } catch (error) {
        console.error("Error starting conversation:", error);
      }
    };
    startConversation();
  }, []);

  const sendMessage = async () => {
    if (!inputMessage.trim() || !threadId) return;

    // Add user message to the chat
    setMessages((prev) => [
      ...prev,
      { sender: "User", text: inputMessage },
    ]);

    setInputMessage(""); 
    setLoading(true);

    try {
      // Send the message to the backend
      await axios.post("http://127.0.0.1:5001/api/send_message", {
        thread_id: threadId,
        message: inputMessage,
      });

      // Fetch responses from assistants
      const response = await axios.post("http://127.0.0.1:5001/api/get_responses", {
        thread_id: threadId,
      });

      const assistantResponses = response.data.responses;
      setMessages((prev) => [
        ...prev,
        ...assistantResponses.map((res) => ({
          sender: res.name,
          text: res.response,
        })),
      ]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="gened1091">
      <div className="chat-container">
        <ScrollToBottom className="chat-box">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.sender === "User" ? "user-message" : "assistant-message"}`}
            >
              <strong>{msg.sender}:</strong> {msg.text}
            </div>
          ))}
        </ScrollToBottom>
        <div className="input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type your message..."
            disabled={loading}
          />
          <button onClick={sendMessage} disabled={loading}>
            {loading ? "Sending..." : "Send"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GENED1091;
