// src/chatbot_components/MainInterface/Nodes/InitialOptionsNode.js

import React from 'react';
import { Handle } from 'react-flow-renderer';
import './InitialOptionsNode.css';

const InitialOptionsNode = React.memo(({ data }) => {
  const { onOptionClick, imagesArray = [], selectedPrompt } = data;
  const hasSelection = selectedPrompt !== null;

  return (
    <div className="initial-options-node">
      <Handle type="target" position="top" style={{ opacity: 0 }} />
      <div className="initial-options-title">Suggested Prompts</div>

      <div className="initial-options-card-container">
        {imagesArray.map((item, idx) => {
          const isSelected = selectedPrompt === item.prompt;
          return (
            <div
              key={idx}
              className={
                'initopt-card ' +
                (isSelected ? 'used-card' : hasSelection ? 'greyed-out' : '')
              }
              onClick={() => {
                if (!hasSelection) {
                  onOptionClick(item.prompt);
                }
              }}
            >
              <img src={item.src} alt={`Option ${idx + 1}`} />
              <div className="option-text">
                {item.badge?.label && (
                  <span className={`option-badge badge-${item.badge.category}`}>
                    {item.badge.label}
                  </span>
                )}
                {item.prompt}
              </div>
            </div>
          );
        })}
      </div>

      <Handle type="source" position="bottom" id="bottom" style={{ top: '100%' }} />
    </div>
  );
});

export default InitialOptionsNode;
