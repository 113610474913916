// src/chatbot_components/MainInterface/Menu/PedagogicalOutcomesOverlay.js

import React from 'react';
import './PedagogicalOutcomesOverlay.css';

const PedagogicalOutcomesOverlay = ({ onClose }) => {
  // This handler checks if the user clicked on the dark backdrop (i.e., the parent overlay div).
  // If so, close the overlay.
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="outcomes-overlay-card" onClick={handleOverlayClick}>
      <div className="outcomes-overlay-content" onClick={(e) => e.stopPropagation()}>
        <button className="outcomes-close-button" onClick={onClose}>X</button>
        
        <h2>Pedagogical Outcomes</h2>
        <p>
          The following outcomes detail the key topics and learning goals for this course
          on complex analysis, Fourier methods, and signal processing fundamentals.
        </p>

        {/* Outcome 1 */}
        <h3>1. Algebra and Geometry of Complex Numbers</h3>
        <ul>
          <li>Learn to manipulate complex numbers: addition, subtraction, multiplication and division.</li>
          <li>
            Learn the geometrical interpretation of complex numbers and the polar representation. 
            Interpret the basic operations using geometrical ideas.
          </li>
          <li>Understand the algebraic and geometric meaning of complex conjugates.</li>
          <li>Understand the geometry and algebra associated with Euler's formula.</li>
          <li>Use the above concepts to calculate the n<sup>th</sup> roots of identity, and simple power functions.</li>
        </ul>

        {/* Outcome 2 */}
        <h3>2. Functions of a Complex Variable</h3>
        <ul>
          <li>Learn to generalize from real-valued functions to complex-valued functions.</li>
          <li>Learn the notion of continuity and limits.</li>
          <li>Learn to visualize complex-valued functions.</li>
          <li>Understand branch points and branch cuts.</li>
          <li>
            Deploy the above concepts to characterize elementary functions (linear, polynomials, rational 
            functions, exponential, trigonometric, logarithmic, etc.).
          </li>
        </ul>

        {/* Outcome 3 */}
        <h3>3. Differentiation of Complex-Valued Functions</h3>
        <ul>
          <li>Understand the notion of analyticity = single-valuedness and differentiability.</li>
          <li>Understand the Cauchy-Riemann equations and their geometric interpretation.</li>
          <li>Wirtinger derivatives and their interpretation.</li>
          <li>Understand harmonic functions and simple physical interpretation.</li>
          <li>Deploy the above on examples.</li>
        </ul>

        {/* Outcome 4 */}
        <h3>4. Integration of Complex-Valued Functions</h3>
        <ul>
          <li>Understand contour integrals as a generalization of line integrals in real-variable calculus.</li>
          <li>Understand the idea of path independence of integrals.</li>
          <li>Understand Cauchy's integral theorem and formula and its geometric significance.</li>
          <li>Cauchy's integral formula.</li>
        </ul>

        {/* Outcome 5 */}
        <h3>5. Power Series and Residue Theory</h3>
        <ul>
          <li>Understand Laurent series as a generalization of Taylor series.</li>
          <li>Understand singularities in power series expansions.</li>
          <li>Understand residue theory and contour integration.</li>
          <li>Understand Jordan's Lemma and behavior at infinity.</li>
          <li>Deploy these concepts to calculate real-valued integrals.</li>
        </ul>

        {/* Outcome 6 */}
        <h3>6. Conformal Maps</h3>
        <ul>
          <li>Understand the geometry of conformal maps.</li>
          <li>Understand the Riemann sphere.</li>
          <li>Understand Möbius transformations.</li>
          <li>Understand the Riemann mapping theorem qualitatively.</li>
          <li>Deploy these concepts to morphometry and anamorphosis.</li>
        </ul>

        {/* Outcome 7 */}
        <h3>7. Fourier Series</h3>
        <ul>
          <li>Understand Fourier series.</li>
          <li>Calculate Fourier series expansions of periodic functions.</li>
          <li>Understand orthogonality, completeness, and convergence of Fourier series.</li>
          <li>Understand the Wilbraham-Gibbs phenomenon.</li>
        </ul>

        {/* Outcome 8 */}
        <h3>8. Fourier Transforms</h3>
        <ul>
          <li>Understand definition and calculation of Fourier and Inverse Fourier transforms.</li>
          <li>Calculate Fourier transforms of delta and step functions.</li>
          <li>Understand the links to residue theory and complex analysis.</li>
          <li>
            Characterize properties of Fourier transforms: duality, linearity, modulation, shift, 
            Parseval's identity, etc.
          </li>
          <li>Study the uncertainty relation and time-frequency tradeoff qualitatively.</li>
        </ul>

        {/* Outcome 9 */}
        <h3>9. Signal Processing Fundamentals</h3>
        <ul>
          <li>
            Grapple with the question of how to process signals—sampling, deconstruction, compression,
            comparison, filtering, construction.
          </li>
          <li>Understand Nyquist and Shannon theorems for sampling.</li>
          <li>Understand convolution and filtering.</li>
        </ul>

        {/* Outcome 10 */}
        <h3>10. Fast Fourier Transforms and Signal Processing</h3>
        <ul>
          <li>Computation of Fourier transform—FFT.</li>
          <li>Understand time-frequency uncertainty in discrete settings.</li>
          <li>Algorithms for FFT.</li>
          <li>Learn to deploy FFT algorithms for music analysis.</li>
          <li>Limits of Fourier analysis—time-frequency uncertainty principle and its consequences.</li>
        </ul>

        {/* Outcome 11 */}
        <h3>11. Filters and Signal Processing</h3>
        <ul>
          <li>Understand low-pass and high-pass filters.</li>
          <li>Understand filter banks and the link to matrix operations.</li>
          <li>Use filtering for denoising and compression in images.</li>
     
        </ul>
        
        <h3>12. Review </h3>
        <ul>
          <li>Complex Functions - Why? </li>
          <li>Complex Functions and Mappings - How?</li>
          <li>Complex Differnetiation - What? </li>
          <li> Conformal Maps and Escher? </li>
          <li>Integration - Countour Integrals and Area Integrals </li>
          <li> Power Series </li>
          <li> Fourier Series and Transforms </li>
          <li> Signal Processing Fundamentals </li>
          <li> Noise reduction and image deblurring </li>
        </ul>
      </div>
    </div>
  );
};

export default PedagogicalOutcomesOverlay;
