// src/chatbot_components/MainInterface/Nodes/MobiusNode.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { SERVER_ENDPOINT } from '../../config';
import './MobiusNode.css';

const MobiusNode = React.memo(({ data }) => {
  // Provided by parent
  const {
    plotData: initPlotData = null,
    isCmdKeyHeld = false, // so we can lock/unlock interactions
  } = data;

  // Möbius parameters stored as strings so user input won't be overwritten
  const [a, setA] = useState('1');
  const [b, setB] = useState('0');
  const [c, setC] = useState('0');
  const [d, setD] = useState('1');
  const [domain, setDomain] = useState('plane');
  const [radius, setRadius] = useState('2');
  const [resolution, setResolution] = useState('100');

  // We only fetch on "Refresh," not automatically
  const [plotData, setPlotData] = useState(initPlotData);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // If you want “Press Control to edit” overlay
  const [isHovered, setIsHovered] = useState(false);

  const MOBIUS_ENDPOINT = `${SERVER_ENDPOINT}/mobius-transform`;

  // If there is no initPlotData and you want to fetch once on mount, uncomment:
  /*
  useEffect(() => {
    if (!initPlotData) {
      handleFetch();
    }
  }, []);
  */

  // Only fetch when user clicks Refresh
  const handleFetch = async () => {
    setIsLoading(true);
    setErrorMsg('');
    try {
      const aNum = parseFloat(a) || 0;
      const bNum = parseFloat(b) || 0;
      const cNum = parseFloat(c) || 0;
      const dNum = parseFloat(d) || 1;
      const radNum = parseFloat(radius) || 1;
      const resNum = parseInt(resolution, 10) || 100;

      const resp = await axios.post(
        MOBIUS_ENDPOINT,
        {
          a: aNum,
          b: bNum,
          c: cNum,
          d: dNum,
          domain,
          radius: radNum,
          resolution: resNum,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setPlotData(resp.data);
    } catch (err) {
      console.error('Mobius fetch error:', err);
      setErrorMsg('Server error or invalid input');
      setPlotData(null);
    }
    setIsLoading(false);
  };

  // Build the domain / plane / sphere traces if we have data
  let domainTrace, imageTrace, sphereTrace;
  if (plotData) {
    const { xDomain, yDomain, wReal, wImag, sphereX, sphereY, sphereZ } = plotData;

    const domainX = xDomain?.flat() || [];
    const domainY = yDomain?.flat() || [];
    const imageX = wReal?.flat() || [];
    const imageY = wImag?.flat() || [];
    const sphere_x = sphereX?.flat() || [];
    const sphere_y = sphereY?.flat() || [];
    const sphere_z = sphereZ?.flat() || [];

    domainTrace = {
      x: domainX,
      y: domainY,
      mode: 'markers',
      marker: { color: 'blue', size: 2 },
      name: 'Domain',
      type: 'scatter', // 2D
    };
    imageTrace = {
      x: imageX,
      y: imageY,
      mode: 'markers',
      marker: { color: 'red', size: 2 },
      name: 'Mapped',
      type: 'scatter',
    };
    sphereTrace = {
      x: sphere_x,
      y: sphere_y,
      z: sphere_z,
      mode: 'markers',
      marker: { color: 'green', size: 2 },
      name: 'Riemann Sphere',
      type: 'scatter3d', // 3D
    };
  }

  // We also add "uirevision" to preserve camera/orbit if the React node re-renders
  // A stable string or number is enough if you do not want to reset the camera. 
  // If you do want the camera to reset when the user changes (a,b,c,d,etc), 
  // you can incorporate them into uirevision. For now, we keep it stable.
  const layout2D = {
    width: 250,
    height: 250,
    margin: { l: 40, r: 10, b: 40, t: 10 },
    xaxis: { scaleanchor: 'y', scaleratio: 1 },
    yaxis: {},
    uirevision: 'mobius-2d', // ensures no camera reset in 2D plots
  };

  const layout3D = {
    width: 250,
    height: 250,
    margin: { l: 0, r: 0, b: 0, t: 10 },
    scene: {
      dragmode: 'orbit', // single-click & drag rotates
      xaxis: { range: [-1, 1] },
      yaxis: { range: [-1, 1] },
      zaxis: { range: [-1, 1] },
    },
    uirevision: 'mobius-3d', // ensures 3D orbit doesn't reset
  };

  // Also disable double-click reset:
  const commonConfig = {
    doubleClick: false,   // no single/double-click resetting
    scrollZoom: true,
    displayModeBar: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['toImage', 'toggleSpikelines'],
  };

  // For pointerEvents
  const pointerStyle = { pointerEvents: isCmdKeyHeld ? 'auto' : 'none' };

  return (
    <div
      className="mobius-node-card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Handle type="target" position="top" />

      <h2 className="mobius-node-title">Möbius Transformation</h2>

      {/* If not holding Control + hovering => block interaction */}
      {!isCmdKeyHeld && isHovered && (
        <div className="command-overlay">
          <div className="overlay-text">
            Press Control <span className="command-key-symbol">^</span> to edit
          </div>
        </div>
      )}

      {/* Top row: domain, plane, sphere side by side */}
      <div className="mobius-plots-row">
        {/* Domain (2D) */}
        <div className="plot-wrapper">
          {plotData ? (
            <Plot
              data={[domainTrace]}
              layout={layout2D}
              style={pointerStyle}
              config={commonConfig}
            />
          ) : (
            <div>No Domain Data</div>
          )}
        </div>
        {/* Mapped plane (2D) */}
        <div className="plot-wrapper">
          {plotData ? (
            <Plot
              data={[imageTrace]}
              layout={layout2D}
              style={pointerStyle}
              config={commonConfig}
            />
          ) : (
            <div>No Plane Data</div>
          )}
        </div>
        {/* 3D Sphere */}
        <div className="plot-wrapper">
          {plotData ? (
            <Plot
              data={[sphereTrace]}
              layout={layout3D}
              style={pointerStyle}
              config={commonConfig}
            />
          ) : (
            <div>No Sphere Data</div>
          )}
        </div>
      </div>

      {/* Parameter inputs below */}
      <div className="mobius-params">
        <div className="param-row">
          <label>
            A:
            <input
              type="text"
              value={a}
              onChange={(e) => setA(e.target.value)}
            />
          </label>
          <label>
            B:
            <input
              type="text"
              value={b}
              onChange={(e) => setB(e.target.value)}
            />
          </label>
          <label>
            C:
            <input
              type="text"
              value={c}
              onChange={(e) => setC(e.target.value)}
            />
          </label>
          <label>
            D:
            <input
              type="text"
              value={d}
              onChange={(e) => setD(e.target.value)}
            />
          </label>
        </div>
        <div className="param-row">
          <label>
            Domain:
            <select value={domain} onChange={(e) => setDomain(e.target.value)}>
              <option value="disk">Disk</option>
              <option value="plane">Plane</option>
            </select>
          </label>
          <label>
            Radius:
            <input
              type="text"
              value={radius}
              onChange={(e) => setRadius(e.target.value)}
            />
          </label>
          <label>
            Resolution:
            <input
              type="text"
              value={resolution}
              onChange={(e) => setResolution(e.target.value)}
            />
          </label>
          <button onClick={handleFetch} style={{ marginLeft: '10px' }}>
            {isLoading ? 'Loading...' : 'Refresh'}
          </button>
        </div>

        {errorMsg && <div className="error-msg">{errorMsg}</div>}
      </div>

      <Handle type="source" position="bottom" />
    </div>
  );
});

export default MobiusNode;
