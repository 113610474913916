// ConformalNode.jsx
import React from 'react';
import { Handle } from 'react-flow-renderer';
import Plot from 'react-plotly.js';

/**
 * Renders the domain X,Y and mapped coordinates realMapped,imagMapped.
 * Possibly do a 3D surface or a 2D quiver plot, etc.
 */
export default function ConformalNode({ data }) {
  const { plotData } = data;
  if (!plotData) {
    return (
      <div style={{ padding: '10px' }}>
        <strong>Conformal Node:</strong> No data received yet.
      </div>
    );
  }
  // unpack:
  const { xDomain, yDomain, realMapped, imagMapped, mappingFunction, patternType } = plotData;

  // e.g. we can do a 2D scatter plot of (xDomain, yDomain) -> (realMapped, imagMapped).
  // For instance, flatten them and then show them in Plotly
  const flattenedX = xDomain.flat();
  const flattenedY = yDomain.flat();
  const mappedX = realMapped.flat();
  const mappedY = imagMapped.flat();

  // We can show 2 subplots: original domain vs mapped domain
  const domainTrace = {
    x: flattenedX,
    y: flattenedY,
    mode: 'markers',
    name: 'Original Domain',
    marker: { color: 'blue', size: 2 },
  };

  const mappedTrace = {
    x: mappedX,
    y: mappedY,
    mode: 'markers',
    name: 'Mapped Domain',
    marker: { color: 'red', size: 2 },
  };

  return (
    <div style={{ background: '#fafafa', border: '1px solid #ccc', borderRadius: 10, padding: 10 }}>
      <Handle type="target" position="top" />
      <div><strong>Conformal Mapping Node</strong></div>
      <div>Function: {mappingFunction}, Pattern: {patternType}</div>
      <Plot
        data={[domainTrace]}
        layout={{
          title: 'Original Domain',
          xaxis: { title: 'X' },
          yaxis: { title: 'Y', scaleanchor: 'x', scaleratio: 1 },
          margin: { l: 40, r: 10, b: 40, t: 40 },
        }}
        style={{ width: '400px', height: '300px' }}
      />
      <Plot
        data={[mappedTrace]}
        layout={{
          title: 'Mapped Domain',
          xaxis: { title: 'Re(f(z))' },
          yaxis: { title: 'Im(f(z))', scaleanchor: 'x', scaleratio: 1 },
          margin: { l: 40, r: 10, b: 40, t: 40 },
        }}
        style={{ width: '400px', height: '300px' }}
      />
      <Handle type="source" position="bottom" />
    </div>
  );
}
