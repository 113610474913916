// src/chatbot_components/MainInterface/CourseSelectorModal.js

import React from 'react';
import './CourseSelectorModal.css';

function CourseSelectorModal({
  courses = [],
  onCourseSelected,
  onClose
}) {
  if (!courses || courses.length === 0) {
    // If no courses, hide the modal or return null
    return null;
  }

  return (
    <div className="course-selector-overlay">
      <div className="course-selector-modal">
        {/* “X” in the top-right corner */}
        <div className="close-icon" onClick={onClose}>
          X
        </div>

        <h2>Choose Your Class</h2>
        <p>
          You are registered in the following course
          {courses.length > 1 ? 's' : ''}:
        </p>

        <div className="course-card-container">
          {courses.map((course, idx) => (
            <div
              key={idx}
              className={
                'course-card ' +
                (course === localStorage.getItem('selectedClass') ? 'active-course-card' : '')
              }
              onClick={() => onCourseSelected(course)}
            >
              {course}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseSelectorModal;
