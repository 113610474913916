// src/chatbot_components/MainInterface/FloatingBar.js

import React from 'react';
import { FaPaperPlane, FaSyncAlt } from 'react-icons/fa';
import './FloatingBar.css';

function FloatingBar({
  input,
  setInput,
  addUserMessage,
  onRefresh,
  isCmdKeyHeld = false, // new prop to indicate command key status
}) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && input.trim() && !isCmdKeyHeld) {
      e.preventDefault();
      console.log('[DEBUG] FloatingBar => Enter =>', input);
      addUserMessage(input);
    }
  };

  const handleSendClick = () => {
    if (input.trim() && !isCmdKeyHeld) {
      console.log('[DEBUG] FloatingBar => click =>', input);
      addUserMessage(input);
    }
  };

  const handleRefreshClick = () => {
    console.log('[DEBUG] FloatingBar => refresh clicked');
    onRefresh();
  };

  return (
    <div className="floating-bar-container">
      {/* If Command is pressed, show a small note above the bar */}
      {isCmdKeyHeld && (
        <div className="command-pressed-message">
          Command key is pressed – screen locked, but interactive nodes are editable!
        </div>
      )}

      <div className={`floating-bar ${isCmdKeyHeld ? 'bar-disabled' : ''}`}>
        <input
          type="text"
          className="floating-input"
          placeholder="Ask me anything..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isCmdKeyHeld} 
        />
        <div className="button-group">
          <button 
            className="floating-button" 
            onClick={handleSendClick}
            disabled={isCmdKeyHeld}
          >
            <FaPaperPlane />
          </button>
          <button className="refresh-button" onClick={handleRefreshClick}>
            <FaSyncAlt />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FloatingBar;
